@import "Components/BlogRoom.css";
@import "Components/PhotoGallery.css";
@import "Components/TechLife.css";
@import "Components/Portfolio.css";
@import "Components/myCard.css";

@media only screen
and (min-device-width: 1347px) {
.container{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 4% 28% 18% 42% ;
    grid-template-areas:
            "nav nav"
            "header portf"
            "me me"
            "photo blog";
}

.navBar {
    grid-area: nav;
    background-color: gray;
}
nav li {
    float: left;
    margin-left: 5%;
    margin-bottom: 0;
}
nav ul {
    margin-bottom: 3vh;
}
nav li a {
 display: block;
font-size: 3vh;
 color: #bac6de;
 text-align: center;
 text-decoration: none;
 }
.top {
    grid-area: header;
    grid-template-columns: 23% 77%;
    grid-template-areas: "corner head";
}
.header {
    width: 85%;
    grid-area: head;
}
.myName {
    font-size: 8vh;
    font-family: "Lucida Calligraphy", sans-serif;
    font-weight: bold;
    color: midnightblue;
    margin: 2% auto 1% 15%;
}
.subTitle {
    font-size: 4.5vh;
    font-family: "Lucida Handwriting", sans-serif;
    color: rgb(40, 40, 180);
    margin-left: 60%;
    font-weight: 500;
    margin-top: -1%;
}
.me {
    width: 60%;
    transform: scaleX(-1);
    margin: 2% auto auto 42%;
    border-radius: 30%;
}
.blog {
    width: 100%;
    grid-area: blog;
    background-color: #cac9c9;
    border-radius: 2%;
    margin-top: 8%;
    padding-bottom: 70%;
}
.photo {
    grid-area: photo;
    margin-top: -3%;
    margin-left: 5%;
}
.coder {
    font-size: 3vh;
    margin-left: -10%;
    font-weight: bold;
    color: #6c016d;
}
.techLife {
    grid-area: me;
    margin-top: 7%;
}
.myCard {
    grid-area: corner;
    margin: -35% 58% 16% 0;
}
.bott {
    margin-bottom: 35%;
}
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 1346px) {

.container {
    grid-area: body;
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
            "header"
            "me"
            "portf"
            "blog"
            "photo";
    }
.top {
    grid-area: header;
    display: grid;
    grid-template-columns: 80vw 20vw;
    grid-template-areas: "header corner";
    }
.header {
    grid-area: header;
    }
.myName {
    font-size: 5vh;
    margin-top: 3vh;
    margin-left: 1vw;
    font-family: "Lucida Calligraphy", sans-serif;
    font-weight: bold;
    color: midnightblue;
}
.subTitle {
    font-size: 3vh;
    font-weight: 400;
    font-family: "Lucida Handwriting", sans-serif;
    color: rgb(40, 40, 180);
    margin-left: 45%;
    margin-top: -1%;
}
.me {
    width: 70vw;
    height: 40vh;
    transform: scaleX(-1);
    margin-left: 3vw;
    margin-top: 3vh;
    border-radius: 25vw;
}
.blog {
    grid-area: blog;
    background-color: #cac9c9;
    border-radius: 2vw;
    margin-top: 2vh;
    }
.photo {
    grid-area: photo;
    }
.girl {
    width: 30vw;
    height: 15vw;
    }
.coder {
    font-size: 2vh;
    margin-left: -10vw;
    font-weight: bold;
    color: #6c016d;
    }
.techLife {
    grid-area: me;
    margin: 5vh 0;
    }
.myCard {
    grid-area: corner;
    margin: 20vh -20vw 0 -1vh;
    }
.navBar {
    width: 0.1vw;
    display: none;
}
.bott {
    margin-bottom: 90%;
}
}
