@import './Tonya/tonya.css';
@media only screen
and (min-device-width: 1347px) {

body {
  background: lightgray;
  font-family: Roboto, sans-serif;
  position: relative;
}
.footContainer {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-areas:
        " left middle right ";
}
.copyright {
  grid-area: middle;
  text-align: center;
  font-size: 2vh;
  margin-top: -10%;
}
.footer {
  grid-area: right;
  color: lightgrey;
  margin-top: -40%;
  right: 0;
  width: 100%;
}
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 1346px) {

  body {
    background: lightgray;
    font-family: Roboto, sans-serif;
    position: relative;
  }
  .appContainer {
    display: grid;
    grid-template-rows: 99% 1%;
    grid-template-areas:
            "body"
            "footer";
  }
  .footContainer {
    width: fit-content;
    grid-area: footer;
  }
  .footer {
    color: lightgrey;
    width: 50%;
    z-index: -1;
    margin-left: 70%;
  }
  .copyright {
    text-align: center;
    font-size: 1.5vh;
    margin: 10% 5% -10% 0;
  }
}
