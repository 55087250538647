@media only screen
and (min-device-width: 1347px) {

.blogRoom {
font-size: 5vh;
font-weight: bolder;
margin: 2% 0 4% 10%;
}
li {
    margin-left: 10%;
    margin-bottom: 2%;
}
ul {
    margin-bottom: 10%;
    list-style-type: none;
}
.blogTitle {
    font-size: 3vh;
    text-decoration: underline;
}
.built {
    margin-left: 16%;
    margin-top: 1%;
    border-radius: 20%;
}
.bh {
    color: midnightblue;
    text-decoration: none !important;
}
video {
    border-radius: 15%;
}
video:active, video:focus {
    outline: none;
}
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 1346px) {
    .blogRoom {
        font-size: 3vh;
        font-weight: bold;
        margin: 2vh 0 2vh 5vw;
    }
    li {
        margin-left: 0;
    }
    ul {
        margin-bottom: 5vh;
        list-style-type: none;
    }
    .blogTitle {
        font-size: 2.3vh;
        text-decoration: underline;
    }
    .blogList {
        padding-left: 3vw;
    }
    .built {
        margin-left: 8vw;
        margin-top: 1vw;
        border-radius: 20vw;
        width: 65vw;
    }
    .builtTitle {
        margin-top: -5vw;
    }
    .bh {
        color: midnightblue;
        margin-top: 8vw;
        text-decoration: none !important;
    }
    video {
        border-radius: 15vw;
    }
    #myvideo {
        margin-top: 2vw;
        width: 90vw;
        height: 55vw;
    }
}
