@media only screen
and (min-device-width: 1347px) {
    .card {
        font-size: 3.3vh;
    }

    .social {
        padding: 0.5% 6%;
        font-size: 2.5vh;
    }

    .socialIcon {
        margin-left: -10%;
        width: 12%;
    }

    .resume {
        margin-top: 15%;
        margin-left: 15%;
        font-size: 3vh;
    }

    a {
        text-decoration: none;
    }
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 1346px) {

    .card {
        padding-left: 0;
    }
    .resume {
       width: 0;
        display: none;
    }
    .social {
        display: flex;
        padding: 3vw;
        font-size: 2.3vh;
    }
    .resume {
        display: none;
        font-size: 2.6vh;
    }
    .socialIcon {
        width: 5vw;
    }
}
