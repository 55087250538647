@media only screen
and (min-device-width: 1347px) {

.tech {
    font-size: 5vh;
    font-weight: bolder;
    margin: -4% 0 0 5%;
}
.life {
    font-size: 2.2vh;
    margin: 1% 0 0 5%;
    padding: 0 11% 0 1%;
    font-family: "Lucida Grande";
}
.wit {
    font: 2.3vh Helvetica;
    color: deeppink;
}
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 1346px)
    /*and (orientation: landscape) {*/ {

    .life {
    font-size: 2vh;
    margin: 1vh 0 0 3vw;
    padding: 0;
}
    .tech {
        font-size: 5vh;
        font-weight: bolder;
    }
    .wit {
        font: 2.2vh Helvetica;
        color: deeppink;
    }

}
